<template>
  <div class="home home-services-main">
    <!-- header -->
    <topNavigation />
    <!-- end header -->
    <!-- <section class="services-top-section first_1">
      <div>
        <img src="/img/about_banner.png" alt="home services" />
      </div>
    </section> -->
    <section class="services-top-section position-relative">
      <div class="service-in-image">
        <img
          src="/img/services_hospital.png"
          height="auto"
          class="service-bg-img lg-d-none"
          sizes="xs:300px sm:320px md:800px lg:900px xl:1100 xxl:1300"
        />
      </div>
      <img
        src="/img/curve.png"
        height="auto"
        class="curve-box position-absolute lg-d-none"
        sizes="xs:300px sm:320px md:800px lg:900px xl:1100 xxl:1300"
      />
    </section>
    <section class="services-list-main">
      <h1>Services</h1>
      <h3>
        DocSmart offers a gamut of services. It is a platform for all stake
        holders to interact among themselves.
      </h3>
      <div class="service-list-top">
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <doctor />
            </div>
            <h3>
              <b>Doctor</b>
            </h3>
            <p>
              All the needs of a doctor in the form of Visibility, Appointment
              Booking, Out Patient Module, In Patient online hospital records
              access, Online Consultation, Telemedicine, Conferences, Online
              Meetings, Webinars, News-Blogs-Forums and Emergency Codes form a
              part of this software. The doctor can actually access health
              records in real time.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <patientSVG />
            </div>
            <h3>
              <b>Patient</b>
            </h3>
            <p>
              The Patients have access in real time to their Medical Records,
              Appointments, Reports, Certificates, Online Consultation, Second
              Opinion and Grievance Redressal. The App offers ease of searching
              Doctors, Ambulances, Hospitals, Pharmacy, Diagnostic centres,
              Blood Banks, Vendors and Services. The App maintains transparency
              of charges towards services and Disease information in real time.
              The Privacy and security of data is maintained at all times.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <ambulance />
            </div>
            <h3>
              <b>Ambulance</b>
            </h3>
            <p>
              Emergency as well as routine ambulances can be booked on line with
              ease. The category of ambulance, their availability, staff
              allocation, and real time location can be seen by the Ambulance
              centre. The Driver mobile app completes the trip with costing. The
              App can be of immense help during a disaster with timely
              allocation of Ambulances.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <blood />
            </div>
            <h3>
              <b>Blood Bank</b>
            </h3>
            <p>
              Online emergency booking and reservation of blood is possible via
              the DocSmart App. Availability of blood, Issuing of Blood, Blood
              Donation , Online Camp Organizer, Staff allocation, Testing of
              blood and Technician module is provided. Delivery of blood to
              hospitals can be followed in real time. This prevents wastage of
              Blood.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <hospital />
            </div>
            <h3>
              <b>Hospital</b>
            </h3>
            <p>
              Hospitals get online reach to Patients, Doctors and Insurance.
              Online searchable variants incorporated are: Services offered,
              Panel of Doctors, Health and Disease packages and Emergency
              Facility available. Hospitals will be given the Polyclinic and IPD
              software which includes staff, stock and accounting modules. The
              software incorporates various emergency codes and protocols and
              also crowd management systems.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <prescription />
            </div>
            <h3>
              <b>Pharmacy</b>
            </h3>
            <p>
              DocSmart offers online reach to patients and E prescription
              connect. It incorporates a pharmacy software having both generic
              and brand names of medicines. It has provision for online purchase
              from stockists and auto update of medicines data. It also has a
              provision for home delivery of medicine.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <medicalRecord />
            </div>
            <h3>
              <b>Diagnostic</b>
            </h3>
            <p>
              Diagnostic centres are seen in search. E booking of Tests and
              Health packages can be done directly by the patient or the Doctor.
              Online reports are shared with the patient and the doctor .There
              is a provision for Critical value reporting, Technician module for
              Collection, Testing and Home visit and also a provision for
              insurance and company health check for patients.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <chat />
            </div>
            <h3>
              <b>Chat</b>
            </h3>
            <p>
              All modules are integrated with the connect/ contact feature. This
              allows Calling, Video Calling, Conference calling, intra App
              Messaging and Intra Centre Chat. Chatrooms are created for various
              diseases.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <seller />
            </div>
            <h3>
              <b>Vendor</b>
            </h3>
            <p>
              Vendors can reach out to target clients to sell their products
              online. Products can be listed as per Company,Costings and other
              parameters. Online Payment and Bulk Selling is possible. There is
              a provision towards tracking of delivery both by vendors and
              clients.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <stockist />
            </div>
            <h3>
              <b>PHARMA / STOCKIST</b>
            </h3>
            <p>
              Pharmaceutical companies can reach out to doctors digitally. New
              products and existing products information and also events
              propagation can be done online to the target clients. Companies
              can reach out to stockists and retailers for online purchases.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <insurance />
            </div>
            <h3>
              <b>Insurance</b>
            </h3>
            <p>
              The insurance sector gets information in real time about their
              insured clients. Companies can check the status of admission of
              patients in real time. Its unique feature allows digital
              transferring of files and bills with filtering of non-payables.
            </p>
          </div>
        </div>
        <!-- ............................. -->
        <div class="service-list">
          <div class="div1">
            <div class="div1-inner">
              <court />
            </div>
            <h3>
              <b>GOVERNMENT Notification</b>
            </h3>
            <p>
              The Government can see in real time the demography of disease
              prevalence and efficacy of various Government programs. Through
              the system, the Government can plan various strategies and
              evaluate its efficacy. They can reach out to various health care
              providers and citizens to notify them or propagate its various
              schemes.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- footer -->
    <bottomNavigation />
    <!-- end footer -->
  </div>
</template>

<style>
.con-2.w-50v.pt-220.position-relative {
  padding: 0 0 0 5vw;
}

img {
  max-width: 100%;
}
</style>

<script>
import topNavigation from "@/components/masters/topNavigation";
import bottomNavigation from "@/components/masters/bottomNavigation";
import { pickSvg } from "@/svg";
export default {
  components: {
    topNavigation,
    bottomNavigation,
    ...pickSvg(
      "doctor",
      "ambulance",
      "hospital",
      "patientSVG",
      "medicalRecord",
      "prescription",
      "blood",
      "customerService",
      "chat",
      "seller",
      "stockist",
      "court",
      "insurance",
      "packageSVG",
      "interview",
      "party",
      "envelope",
      "whatsappline",
      "twitterLogo"
    ),
  },
};
</script>
<style scoped>
.services-top-section {
  padding: 4.5rem 0 0;
  height: 40rem;
}
.service-in-image {
  width: 100%;
  height: 100%;
}
.service-bg-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.curve-box {
  width: 100%;
  height: 220px;
  bottom: 0;
}
</style>
