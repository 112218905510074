<template>
  <div class="home contact-us-main">
    <!-- header -->
    <topNavigation />
    <!-- end header -->
    <section class="section-1 first_1">
      <div class="top-section-back">
        <img src="/img/contact_us_bkg.png" alt="background" class="ww-100" />
      </div>

      <div class="col-sm-12 contact-head">
        <h1>Contact Us</h1>
        <h3>Have any questions? We would love to hear from you.</h3>
        <div class="contact-div-main">
          <div class="div1">
            <div class="cust-support">
              <div>
                <div class="svg-div">
                  <customerService />
                </div>
              </div>
              <div class="con_top">
                <h6>Customer call center</h6>
                <p>(+91) 7700048012</p>
              </div>
            </div>
            <div class="cust-support">
              <div>
                <div class="svg-div">
                  <email />
                </div>
              </div>
              <div class="con_top">
                <h6>Email</h6>
                <a href="mailto:support@relifems.com">support@relifems.com</a>
              </div>
            </div>
            <div class="cust-support">
              <div>
                <div class="svg-div">
                  <whatsappline />
                </div>
              </div>
              <div class="con_top">
                <h6>WhatsApp</h6>
                <p>(+91) 7208824901</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 form-div">
        <div class="col-sm-12 col-xs-12 p-0">
          <div class="row">
            <div class="col-sm-6 form-group">
              <label class="col-sm-12 p-0 text-left">Name</label>
              <input
                type="text"
                name="Name"
                class="form-control"
                placeholder="Name"
              />
              <!-- <span class="text-danger">{{ errors.first("Name") }}</span> -->
            </div>
            <div class="col-sm-6 form-group">
              <label class="col-sm-12 p-0 text-left">Last Name</label>
              <input
                name="Last Name"
                type="text"
                class="form-control"
                placeholder="Last Name"
              />
              <!-- <span class="text-danger">{{ errors.first("Last Name") }}</span> -->
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 form-group">
              <label class="col-sm-12 p-0 text-left">Email</label>
              <input
                name="email"
                type="text"
                class="form-control"
                placeholder="Email"
              />
              <!-- <span class="text-danger">{{ errors.first("email") }}</span> -->
            </div>
            <div class="col-sm-6 form-group">
              <label class="col-sm-12 p-0 text-left">Phone Number</label>
              <input
                name="Phone Number"
                type="text"
                class="form-control"
                placeholder="Phone Number"
              />
              <!-- <span class="text-danger">
                  {{ errors.first("Phone Number") }}
                </span> -->
            </div>
          </div>

          <div class="col-sm-12 form-group">
            <label class="col-sm-12 p-0 text-left">Message</label>
            <textarea
              name="Message"
              placeholder="Write Here ..."
              class="form-control"
            ></textarea>
            <!-- <span class="text-danger">{{ errors.first("Message") }}</span> -->
          </div>
          <div class="col-sm-12 flex-end">
            <button class="btn btn-grn-solid" @click="submit()">
              Get in Touch
              <span>&#10230;</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <v-dialog
      v-model="contactUsComplete"
      width="clamp(500px,30vw,800px)"
      persistent
    >
      <v-card class="min-height fluid-modal home-service-complete">
        <v-card-text>
          <div class="logo-svg">
            <logo />
          </div>
          <div>
            <h4>Thank You</h4>
            <h6>
              For all your interest in our services we will contact you shortly
            </h6>
          </div>
          <div class="btn-div">
            <v-btn class="btn btn-grn-solid" @click="contactUsComplete = false">
              <span>&#8592;</span> Back to home page
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- footer -->
    <!-- footer -->
    <!-- <bottomNavigation /> -->
    <div class="mt-3vw">
      <img
        src="/img/footer_bkg.png"
        alt
        class="footer-back position-relative hidden-xs"
      />
      <div class="footer-nav">
        <div>
          <ul class="foo-ul lis">
            <li>
              <a href="https://www.docsmart.in/faq" target="_blank">FAQ</a>
            </li>
            <li>
              <router-link :to="{ name: 'About' }" target="_blank"
                >About</router-link
              >
            </li>
            <!-- <li>Whats New</li> -->
            <li>
              <a href="https://www.docsmart.in/terms-conditions" target="_blank"
                >Terms and Conditions</a
              >
              <!-- <router-link :to="{name:'TermsConditions'}" target="_blank">Terms and Conditions</router-link> -->
            </li>
            <li>
              <a href="https://www.docsmart.in/privacy-policy" target="_blank"
                >Privacy Policy</a
              >
              <!-- <router-link :to="{ name: 'PrivacyPolicy'}" target="_blank">Privacy Policy</router-link> -->
            </li>
          </ul>
        </div>
        <div>
          <ul class="foo-ul md-flex">
            <li>
              <a
                href="https://www.facebook.com/DocSmartHealthcareSystem"
                target="_blank"
                ><facebook></facebook
              ></a>
            </li>
            <li>
              <a href="https://www.instagram.com/docsmart.in/" target="_blank"
                ><instagram></instagram
              ></a>
            </li>
            <li>
              <a href="https://twitter.com/DocSmartapp" target="_blank"
                ><twitterLogo></twitterLogo
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/relifems/"
                target="_blank"
                ><linkedin></linkedin
              ></a>
            </li>
            <!-- <li>
                <bloggerLogo></bloggerLogo>
              </li> -->
          </ul>
        </div>
      </div>
      <div class="footer-end">
        <p>All rights reserved. &copy; 2021 RELIFEMS</p>
      </div>
    </div>
    <!-- end footer -->
    <!-- end footer -->
  </div>
</template>

<style>
.contact-us-main .section-1 .top-section-back {
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  height: 85vh;
  opacity: 0.35;
  z-index: 1;
  background-color: #d9f1f0;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.contact-head {
  position: relative;
  top: 5rem;
}
.form-div {
  top: 5rem;
}
.mt-3vw {
  margin-top: 5rem;
}
.footer-nav {
  display: flex;
  padding: 12px;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  bottom: 30px;
  margin-top: -5px;
  align-items: center;
}
.footer-end {
  background-color: rgba(55, 180, 174, 0.7686274509803922);
  width: 100%;
  text-align: center;
}
.foo-ul {
  display: flex;
  margin-bottom: 0;
}
.foo-ul li {
  list-style-type: none;
  color: #efefef;
  font-size: clamp(14px, 0.8vw, 21px);
  margin-right: 15px;
  cursor: pointer;
}
.foo-ul li a {
  color: #efefef;
  text-decoration: none;
}
.foo-ul svg {
  width: clamp(22px, 1.7vw, 40px);
  cursor: pointer;
  opacity: .65;
}

.footer-end p {
  margin: 0;
  color: #fff;
  padding: 10px;
  font-size: clamp(13px, 0.75vw, 19px);
}

.footer-back {
  width: 100vw;
  height: 15vw;
  margin: 0;
}
.footer-back img {
  opacity: 1;
}
</style>
<script>
import topNavigation from "@/components/masters/topNavigation";
import bottomNavigation from "@/components/masters/bottomNavigation";
import { pickSvg } from "@/svg";
export default {
  components: {
    topNavigation,
    bottomNavigation,
    ...pickSvg(
      "doctor",
      "ambulance",
      "hospital",
      "patientSVG",
      "medicalRecord",
      "prescription",
      "blood",
      "customerService",
      "chat",
      "seller",
      "stockist",
      "court",
      "insurance",
      "packageSVG",
      "interview",
      "party",
      "envelope",
      "whatsappline",
      "twitterLogo",
      "email"
    ),
  },
  data() {
    return {
      contactUsComplete: false,
    };
  },
};
</script>

