<template>
  <div class="about-us-main home-main-wrapper">
    <!-- header -->
    <topNavigation />
    <!-- end header -->
    <section class="top-img-section first_1">
      <img
        src="/img/about_us_holding_ball.png"
        alt="home about us"
        class="w-100"
      />
    </section>
    <section class="section-2">
      <h1 class="mt-0">About Docsmart</h1>
      <p class="p-info">
        Relife Medicare Services is offering a technology-driven medical portal,
        “DocSmart”, which is aimed at re-defining the health care system and
        increasing the convenience for all- patients, doctors, blood banks,
        pharmacies, diagnostic centres and other parties.
      </p>
      <p class="p-info">
        Founded by Dr. Deepak Baid, with numerous achievements attached to his
        name, this software aims to redesign the medical landscape. DocSmart
        enables patients to book appointments, order medicines, pre-book
        diagnostic tests , call an ambulance and view blood banks/hospitals in
        their vicinity, increasing patient convenience. In addition to
        convenience, another crucial issue of safety and security of all users
        is addressed. All in all, Dr. Baid’s vision and mission of a more
        inclusive and accessible healthcare ecosystem is furthered by this
        software.
      </p>
    </section>
    <section class="section-3">
      <h1>Our Directors</h1>
      <!-- <p class="p-info">
        What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the
        industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make
        a type specimen book it has?
      </p>-->
    </section>
    <section class="section-4">
      <div class="founder-left">
        <div class="founder-img">
          <img src="/img/deepak.png" alt="Deepak Baid" />
        </div>
        <div class="fonder-1">
          <div class="founder-name">
            <leftQuote />
            <p>Dr Deepak Baid -</p>
            <p>Founder and Director</p>
          </div>
          <div class="info-div">
            <p class="p-info">
              Dr. Deepak Baid is a consulting physician with nearly 25 years of
              experience. He is a gold medalist in internal medicine. He is the
              founder of Nulife Hospital, which is a leading hospital in
              suburban Mumbai. He has experience in working for both- government
              and private hospitals. He is an infectious disease specialist, and
              has to his credit, the only research article on increasing
              platelets in dengue patients with the use of the injection-
              Filgrastim. He has been the president of prestigious associations
              such as AMC (Association of Medical Consultants) and GMA
              (Ghatkopar Medical Association).
              <leftQuote />
            </p>
          </div>
        </div>
      </div>
      <!-- ............................... -->
      <div class="founder-right">
        <div class="fonder-1">
          <div class="founder-name">
            <leftQuote />
            <p>Mrs Indu Baid -</p>
            <p>Director</p>
          </div>
          <div class="info-div">
            <p class="p-info">
              Mrs. Indu Baid has been the founder/ director of Nulife Hospital,
              a multi-speciality hospital, since 2003. After completing her M.Sc
              degree and publishing an international research article, she
              became an approved FDA licence holder. She then went on to attain
              a diploma in Business Management. Having a business mindset, she
              has been influential in the growth of the hospital. She
              specializes in management, marketing, execution and finance.
              <leftQuote />
            </p>
          </div>
        </div>
        <div class="founder-img">
          <img src="/img/indu.png" alt="Indu Baid" />
        </div>
      </div>
      <!-- ............................... -->
      <div class="founder-left">
        <div class="founder-img">
          <img src="/img/jayesh.png" alt="Jayesh" />
        </div>
        <div class="fonder-1">
          <div class="founder-name">
            <leftQuote />
            <p>Jayesh Bhanushali -</p>
            <p>Director</p>
          </div>
          <div class="info-div">
            <p class="p-info">
              He comes from Entrepreneurs family, having 27 yrs. of vast
              experience and knowledge of various business. He has 10 yrs.
              experience of IT industry and project management. He has worked on
              the SAP (Sales and Distribution )module, ERP systems, expertise in
              banking & Finance software and has credit of implementation of
              banking software across India. He has been a pioneer in planning ,
              marketing, and business growth.
              <leftQuote />
            </p>
          </div>
        </div>
      </div>
      <!-- ............................... -->
      <div class="founder-right">
        <div class="fonder-1">
          <div class="founder-name">
            <leftQuote />
            <p>Dr Vivika Jain -</p>
            <p>Director</p>
          </div>
          <div class="info-div">
            <p class="p-info">
              Vivika Jain has embarked on her presence in the healthcare
              industry for nine years. Her expertise incorporates coordination
              between various healthcare aspects including quality analysis and
              improvement in the healthcare system. Apart from this, she has
              worked on this project from its advent and worked hard to make
              this project reach the pinnacle. She has also been a co-author in
              a research article on "Increasing platelets in dengue patients
              with the use of injection-Filgratism”
              <leftQuote />
            </p>
          </div>
        </div>
        <div class="founder-img">
          <img src="/img/vivika.png" alt="Vivika Jain" />
        </div>
      </div>
    </section>
    <!-- section 5 -->
    <section class="section-5">
      <div class="back-img">
        <img src="/img/our_team.png" alt="background image" class="w-100" />
      </div>
      <div class="our-team">
        <h1>Our Team</h1>
        <p class="p-info">
          We are a team of highly motivated professionals with the ability to
          work together towards a common vision, the ability to direct
          individual accomplishments towards organisational objectives and lay
          the foundation of our success.
        </p>
        <a
          class="btn btn-grn-solid"
          href="https://angel.co/company/relifems/jobs"
          target="_black"
        >
          Join Our Team
          <backward />
        </a>
      </div>
    </section>
    <!-- end section 5 -->
    <!-- footer -->
    <bottomNavigation />
    <!-- end footer -->
  </div>
</template>
<style>
.about-us-main .top-img-section img {
  padding: 0rem 0rem 4rem 0rem;
}
</style>
<script>
import topNavigation from "@/components/masters/topNavigation";
import bottomNavigation from "@/components/masters/bottomNavigation";
import { pickSvg } from "@/svg";
export default {
  components: {
    topNavigation,
    bottomNavigation,
    ...pickSvg("leftQuote", "backward"),
  },
};
</script>
